<template>
    <div>
        <div class="container">
            <div class="formContent " @click="this.submit">
                <div class="validateName text-start" style="margin-top:30px;margin-left: 50px;">
                    <div class="form-group " :class="{ 'form-group--error bg-gradient-warning text-danger ': $v.name.$error }">
                        <label class="form__label">Name</label>
                        <div class="row">
                            <input class="form__input " v-model.trim="name" style="margin-left:10px;" @input="setName($event.target.value)" />
                        </div>
                        <div class="error " v-if="!$v.name.required">Field is required</div>
                        <div class="error " v-if="!$v.name.minLength">Name must have at least {{$v.name.$params.minLength.min}} letters.</div>
                    </div>
                </div>
                <div class="validateHeight text-start" style="margin-top:30px;margin-left: 50px;">
                    <div class="form-group" :class="{ 'form-group--error bg-gradient-warning text-danger ' : $v.height.$error }">
                        <label class="form__label">Height</label>
                        <div class="row">
                            <input class="form__input" v-model.trim="$v.height.$model" style="margin-left:10px;" />
                        </div>
                        <div class="error  " v-if="!$v.height.required">Field is required</div>
                        <div class="error " v-if="!$v.height.between||!$v.height.numeric">Please enter true height(cm)</div>
                    </div>
                </div>
                <div class="validateGender text-start" style="margin-top:30px;margin-left: 50px;">
                    <div class="form-group" :class="{ 'form-group--error bg-gradient-warning text-danger ': $v.gender.$error }">
                        <label for="">
                            Gender
                        </label>
                        <div class="row">
                            <select class="form_select" v-model.trim="$v.gender.$model" style="margin-left:10px;">
                                <option value="null" disabled>select gender</option>
                                <option value="male">male</option>
                                <option value="female">female</option>
                                <option value="notToSay">prefer not to say</option>
                            </select>
                        </div>
                        <div class="error " v-if="!$v.gender.required">Field is required</div>
                    </div>
                </div>
                <div class="validateEmail text-start" style="margin-top:30px;margin-left: 50px;">
                    <div class="form-group" :class="{ 'form-group--error bg-gradient-warning text-danger ': $v.email.$error }">
                        <label class="form__label">Email</label>
                        <div class="row">
                            <input class="form__input" v-model.trim="email" style="margin-left:10px;" @input="setEmail($event.target.value)" />
                        </div>
                        <div class="error  " v-if="!$v.email.required">Field is required</div>
                        <div class="error  " v-if="!$v.email.email">Email must be correct form.</div>
                    </div>
                </div>
                <div class="validatePhone text-start" style="margin-top:30px;margin-left: 50px;">
                    <div class="form-group" :class="{ 'form-group--error bg-gradient-warning text-danger ': $v.phone.$error }">
                        <label class="form__label">Phone</label>
                        <div class="row">
                            <input class="form__input" v-model.trim="phone" style="margin-left:10px;" @input="setPhone($event.target.value)" />
                        </div>
                        <div class="error  " v-if="!$v.phone.required">Field is required</div>
                        <div class="error  " v-if="!$v.phone.minLength">Phone number must have at least {{$v.phone.$params.minLength.min}} letters.</div>
                        <div class="error " v-if="!$v.phone.numeric">Please enter number.</div>
                    </div>
                </div>
                <div class="submit text-start" style="margin-top:30px;margin-left: 50px;">
                    <button class="submitButton text-center" type="submit" :disabled="submitStatus === 'PENDING'">Submit!</button>
                    <div class="submitMsg" v-if="submitStatus === 'OK'">Thanks for your submission!</div>
                    <div class="submitMsg  bg-gradient-warning text-danger" v-if="submitStatus === 'ERROR'">Please fill the form correctly.</div>
                    <div class="submitMsg" v-if="submitStatus === 'PENDING'">Sending...</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
import { required, minLength, numeric, email, between } from 'vuelidate/lib/validators'
export default {
    data() {
        return {
            name: '',
            phone: '',
            gender: '',
            email: '',
            height: '',
            submitStatus: null
        }
    },
    validations: {
        name: {
            required,
            minLength: minLength(4)
        },
        height: {
            required,
            between: between(100, 200),
            numeric
        },
        phone: {
            required,
            minLength: minLength(8),
            numeric
        },
        gender: { required },
        email: {
            required,
            email
        },
    },
    methods: {
        submit() {
            console.log('submit!')
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'
                setTimeout(() => {
                    this.submitStatus = 'OK'
                }, 500)
            }
        },
        setName(value) {
            this.name = value
            this.$v.name.$touch()
        },
        setPhone(value) {
            this.phone = value
            this.$v.phone.$touch()
        },
        setEmail(value) {
            this.email = value
            this.$v.email.$touch()
        },
    }
}
</script>
<style scoped>
.error {
    font-size: 12px;

}

.form__input {
    
    border-left: none;
    border-top: none;
    border-bottom: 2px solid #000;
    border-right: none;
    width: 201px;
    height: 30px;
}




.form_select {
    width: 201px;
    height: 30px;
    border: 2px solid #2c3e5045;
    border-radius: 10px;

}

.submitMsg {
    font-size: 14px;
    color: #909399
}

.submitButton {
    border-radius: 10px;
    border: 2px solid #2c3e5045;
    padding: 5px 10px 5px 10px;
}
</style>