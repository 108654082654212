<template>
    <div class="nav-wrap fixed-top" :class="windowY==0?'':'shadow-sm'">
        <b-navbar toggleable="lg" class="px-4 container nav-wrap ">
            <b-navbar-brand href="#hero">
                <img src="@/assets/logo.png" alt="">
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <!-- <b-nav-item href="#hero">Home</b-nav-item>
                    <b-nav-item href="#about">About</b-nav-item>
                    <b-nav-item href="#services">Services</b-nav-item>
                    <b-nav-item href="#influencers">Influencers</b-nav-item>
                    <b-nav-item href="#posts">Posts</b-nav-item> -->
                </b-navbar-nav>
                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <!-- <b-nav-item-dropdown text="Lang" right>
                        <b-dropdown-item href="#">EN</b-dropdown-item>
                        <b-dropdown-item href="#">ES</b-dropdown-item>
                        <b-dropdown-item href="#">RU</b-dropdown-item>
                        <b-dropdown-item href="#">FA</b-dropdown-item>
                    </b-nav-item-dropdown> -->
                    <b-nav-item class="item" href="#hero">Home</b-nav-item>
                    <b-nav-item class="item" href="#about">About</b-nav-item>
                    <b-nav-item class="item" href="#services">Services</b-nav-item>
                    <b-nav-item class="item" href="#influencers">Influencers</b-nav-item>
                    <b-nav-item class="item" href="#posts">Posts</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>
<script>
export default {
    name: "Nav",
    data() {
        return {
            windowY: 0
        }
    },
    computed: {

    },
    methods: {
        onScroll(e) {
            if (e) {
                this.windowY = window.top.scrollY;

            }
        }
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll)
    }
}
</script>
<style scoped>
.navbar {
    /*      background-color: #FFF6F6;*/
}

.nav-wrap {
    background-color: white;
}

.item {
    font-size: 18px;
    padding: 15px;
}
</style>