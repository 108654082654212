<template>
    <div style="height: 100vh; width: 100vw; background-color: orange;">
        <div class="d-flex" style="height: 100vh;">
            <div class="wrapper my-auto mx-auto shadow" :class="opened?'opened':''">
                <div class="lid one"></div>
                <div class="lid two"></div>
                <div class="envelope">
                    <div class="logo" @click="open">
                    </div>
                </div>
                <div class="letter">
                    <p>Welcome</p>
                </div>
            </div>
        </div>
        <div class="container">
            <modal width="80%" height="80%" name="example" @before-close="beforeClose">
                <div class="p-4">
                    <div class="text-center">
                        <img class="welcome-img" src="../assets/doggie.gif" alt="">
                    </div>
                    <div class="welcome-message text-justify mb-4">
                        <div class="mb-4">
                            亲爱的【小红书博主】，
                        </div>
                        感谢您来大味麻辣烫进行探店！这是您的专属消费折扣
                        <br>
                        <div class="text-danger text-center mb-4 mt-4">
                            <img class="coupon" src="../assets/coupon.png" alt="">
                        </div>
                        期待您的下次光临，并感谢您让更多粉丝了解这一隐藏在城市中的小秘密。
                        <div class="mt-4">
                            大味麻辣烫敬上
                        </div>
                    </div>
                </div>
            </modal>
        </div>
    </div>
</template>
<script>
export default {
    name: "Invitation",
    data() {
        return {
            opened: false
        }
    },
    methods: {
        open() {
            this.opened = true;

            setTimeout(() => {
                this.$modal.show('example')
            }, 1000);
        },
        beforeClose(event) {
            event.cancel()
        }
    }
}
</script>
<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'ZCOOL KuaiLe', sans-serif;
}

body {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    background-color: #3B4049;
}

.wrapper {
    height: 200px;
    width: 300px;
    background-color: #3760C9;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 0;
}

.lid {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-right: 150px solid transparent;
    border-bottom: 100px solid transparent;
    border-left: 150px solid transparent;
    transform-origin: top;
    transition: transform 0.25s linear;
}

/* Lid when closed */
.lid.one {
    border-top: 100px solid #658ced;
    transform: rotateX(0deg);
    z-index: 3;
    transition-delay: 0.75s;
}

/* Lid when opened */
.lid.two {
    border-top: 100px solid #3760C9;
    transform: rotateX(90deg);
    z-index: 1;
    transition-delay: 0.5s;
}

.coupon {
    width: 100%;
}

.envelope {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-top: 100px solid transparent;
    border-right: 150px solid #C4DFF0;
    border-bottom: 100px solid #C4DFF0;
    border-left: 150px solid #a4d4f2;
    z-index: 3;
}

.letter {
    position: absolute;
    top: 0;
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 15px;
    z-index: 2;
    transition: 0.5s;
}

.letter p {
    text-align: center;
    font-size: 30px;
    margin-top: 30px;
    color: #3B4049;
}

.opened .lid.one {
    transform: rotateX(90deg);
    transition-delay: 0s;
}

.opened .lid.two {
    transform: rotateX(180deg);
    transition-delay: 0.25s;
}

.opened .letter {
    transform: translateY(-50px);
    transition-delay: 0.5s;
}

.welcome-img {
    max-width: 100%;
    width: 130px;
}

.logo {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: white;
    position: absolute;
    left: -25px;
    top: -25px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../assets/david.png');
}
</style>