<template>
    <div>
        <div class="wrapper">
            <div class="row ">
                <div class="col-12 ">
                    <div class="search-bar">
                        <b-input-group>
                            <template #prepend>
                                <b-input-group-text style="display: block !important;background: white;  border-right: none;border-top-right-radius:0px;border-bottom-right-radius: 0px ;"><svg t="1666765693238" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2801" width="15" height="15">
                                        <path d="M469.333333 768c-166.4 0-298.666667-132.266667-298.666666-298.666667s132.266667-298.666667 298.666666-298.666666 298.666667 132.266667 298.666667 298.666666-132.266667 298.666667-298.666667 298.666667z m0-85.333333c119.466667 0 213.333333-93.866667 213.333334-213.333334s-93.866667-213.333333-213.333334-213.333333-213.333333 93.866667-213.333333 213.333333 93.866667 213.333333 213.333333 213.333334z m251.733334 0l119.466666 119.466666-59.733333 59.733334-119.466667-119.466667 59.733334-59.733333z" fill="#444444" p-id="2802"></path>
                                    </svg></b-input-group-text>
                            </template>
                            <b-form-input style="margin-right: 8px;border-left:none ;border-top-right-radius: 8px;border-bottom-right-radius: 8px;" v-model="keyword" placeholder=''></b-form-input>
                            <template #append>
                                <button @click='cutover' style="border: 0px;padding:0px;margin-left:0px;background:none;">
                                    <b-input-group-text style="display: block !important;background:  #FFEEEB">
                                        <svg t="1667730264490" class="icon" viewBox="0 0 1242 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6574" width="15" height="15">
                                            <path d="M1173.937666 966.50009H375.822153a58.181663 58.181663 0 0 1 0-115.678836h798.800003a58.181663 58.181663 0 1 1 0 115.678836zM0.037062 919.954759A101.304542 101.304542 0 1 0 101.341604 821.388178 101.304542 101.304542 0 0 0 0.037062 919.954759z m1173.900604-362.779778H375.822153a58.181663 58.181663 0 0 1 0-115.678835h798.800003a58.181663 58.181663 0 1 1 0 115.678835zM0.037062 510.629651A101.304542 101.304542 0 1 0 101.341604 410.694089 101.304542 101.304542 0 0 0 0.037062 510.629651z m1173.900604-362.779779H375.822153a58.181663 58.181663 0 1 1 0-115.678835h798.800003a58.181663 58.181663 0 1 1 0 115.678835zM0.037062 101.304542A101.304542 101.304542 0 1 0 101.341604 0 101.304542 101.304542 0 0 0 0.037062 101.304542z" fill="#FF2E00" p-id="6575"></path>
                                        </svg>
                                    </b-input-group-text>
                                </button>
                            </template>
                        </b-input-group>
                    </div>
                </div>
            </div>
            <div style="margin-top:8px">
                <div style="display: flex; overflow-x: scroll;">
                    <div class="filter-tags" v-for="c in categories" :key="c">
                        {{c}}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="center" class="map-container">
            <GmapMap :center="center" :zoom="15" map-type-id="roadmap" style="width:100%;height:100%;position: relative;" :options="mapOptions">
                <GmapMarker v-for="(marker, index) in searchedList" :key="index" @click="markerInfo(marker)" :position="marker.position" :clickable="true" :draggable="false" />
                <GmapMarker :position="currentLocation" :clickable="true" :draggable="false" icon="http://maps.gstatic.com/mapfiles/ms2/micons/man.png"></GmapMarker>
            </GmapMap>
        </div>
        <div v-if="showCouponCode">
            <CouponCode couponCode='couponCode' @closeCouponEvent="closeCouponPopUp">
            </CouponCode>
        </div>
        <div class="float" @click="floatbtn">
            <div class="fa fa-plus my-float">
                <div class="trophy mx-auto my-auto">
                    <svg t="1667792675580" class="icon" viewBox="0 0 1100 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2631" width="25" height="25">
                        <path d="M892.82263 409.6 892.82263 204.8C1028.286829 204.8 1029.998787 197.973342 1029.998787 273.066658 1028.281342 348.16 968.269517 409.6 892.82263 409.6L892.82263 409.6ZM206.941848 409.6C131.494962 409.6 69.765692 348.16 69.765692 273.066658 69.765692 197.973342 71.483137 204.8 206.941848 204.8L206.941848 409.6ZM892.82263 136.533342C892.82263 61.44 831.09336 0 755.646474 0L344.118005 0C268.671118 0 206.941848 61.44 206.941848 136.533342-27.969576 136.533342 1.177615 136.533342 1.177615 273.066658 1.177615 385.706658 93.771519 477.866658 206.941848 477.866658 215.512616 477.866658 222.371423 476.162722 230.947675 476.162722 277.241886 609.282722 385.270851 730.453342 515.588201 749.229406L515.588201 955.733342 412.706084 955.733342C393.841619 955.733342 378.412044 971.096064 378.412044 989.866658 378.412044 1008.642722 393.841619 1024 412.706084 1024L687.058397 1024C705.917375 1024 721.352434 1008.642722 721.352434 989.866658 721.352434 971.096064 705.917375 955.733342 687.058397 955.733342L584.176278 955.733342 584.176278 749.229406C714.493627 730.453342 822.517108 610.986658 868.816804 476.162722 875.675611 476.162722 884.251865 477.866658 892.82263 477.866658 1005.99296 477.866658 1098.586866 385.706658 1098.586866 273.066658 1096.874908 136.533342 1126.022097 136.533342 892.82263 136.533342L892.82263 136.533342Z" p-id="2632" fill="#1afa29"></path>
                    </svg>
                </div>
            </div>
        </div>
        <div class="storepopup " v-if="selectedMerchant&&!this.showCouponCode">
            <div class="row">
                <div class="col-auto" style="padding-right: 0px;" @click='popup(selectedMerchant.id)'>
                    <!-- <img src="/static/store-img.png"> -->
                    <div class="d-flex" :style="'height:40px; width: 40px;  border-radius: 8px; background-color:' +getRandomBackgroundColor()">
                        <div class="mx-auto my-auto">
                            {{ selectedMerchant.name.slice(0,1) }}
                        </div>
                    </div>
                </div>
                <div class="col-auto mt-auto" style="margin-left: 16px;">
                    <div class="row h-100 ">
                        <div class="col-auto text-start " style="padding-left:0px;margin-bottom: 2px;">
                            <div class="store-name no-line-change" @click='popup(selectedMerchant.id)'>{{selectedMerchant.name}}
                            </div>
                        </div>
                    </div>
                    <div class="row h-100 ">
                        <div class="col-auto mt-auto text-start" style="padding-left:0px;">
                            <div class="store-addr no-line-change">
                                {{selectedMerchant.address}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col text-end">
                    <button style="border:none;background: none;padding: 0px;" @click="closepopup">x</button>
                </div>
                <div class="row" style="margin-top:16px">
                    <div class="col-auto mt-auto" style="margin-left: 4px;padding-right: 0px;">
                        <div class="tag mt-auto" id="weightedIndex">
                            <div><span style="">x</span>{{selectedMerchant.weightedIndex}}</div>
                        </div>
                    </div>
                    <div class="col-auto mt-auto" style="margin-left: 4px;padding-left: 0px;padding-right: 0px;">
                        <div class="tag mt-auto " id="discount">
                            <div>{{selectedMerchant.influencerDiscount}}% off</div>
                        </div>
                    </div>
                    <div class="col-auto mt-auto" style="margin-left: 4px;padding-left: 0px;padding-right: 0px;">
                        <div class="tag mt-auto" id="catagory2">
                            <div>{{selectedMerchant.category}}</div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top:16px">
                    <div class="col-auto text-start">
                        <div class="description" id="title">
                            You should have :
                        </div>
                        <div class="row" style="margin-top:8px">
                            <div class="col-auto">
                                <div v-html="selectedMerchant.requirements" class="description">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top:20px">
                    <div class="col-auto text-start">
                        <div class="navigation" @click='navigation'><span><img src="/static/car.png"></span> Navigate to store </div>
                    </div>
                    <div class="col text-end">
                        <div class="navigation" @click='openCouponPopUp'>
                            <div class="navigation"><span><img src="/static/coupon.png"></span> Get Coupon</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="navigatemap" v-if='this.showmap'>
            <div class="row" style="margin-top:12px">
                <div class="col-12">
                    <div class="withGoogleMap" @click="withgooglemap">
                        <div class="row">
                            <div class="col">
                                Google Map
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="space1" style="height:1px;margin-top: 10px;background:grey;"> </div>
            <div class="row" style="margin-top:12px">
                <div class="col-12">
                    <div class="withAppleMap" @clcik="withapplemap">
                        <div class="row">
                            <div class="col">
                                Apple Map
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="space1" style="height:1px;margin-top: 10px;background:grey;"> </div>
            <div class="row" style="margin-top:30px;padding-right:30px;">
                <div class="col-12 text-end" style="padding-right:0px">
                    <div class="cancelnavigate" @click="cancel">
                        Cancel
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div v-if="searched" class="bottom">
                <div class="row">
                    <div class="col text-center">
                        <div class="btn" id="store-number">
                            32 Stores In this area
                        </div>
                    </div>
                </div>
            </div>
            <div v-if=" showSearch && !searched" class="bottom">
                <div class="row my-auto">
                    <div class="col-auto text-center">
                        <div @click="searched=true; showSearch = false" class="btn" id="area">
                            Search this area
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { gmapApi } from 'vue2-google-maps';
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import CouponCode from '@/components/CouponCode.vue'


export default {
    name: 'Gmap',


    data() {
        return {
            mapbtn: false,
            markers: [],
            mapOptions: {
                styles: [{
                        elementType: "geometry",
                        stylers: [{ color: "f1f1f1" }],
                    },
                    {
                        featureType: 'landscape',
                        elementType: "labels",
                        stylers: [
                            { visibility: "on" },
                        ]
                    },


                    {
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#757575" }],
                    },
                    {
                        elementType: "labels.text.stroke",
                        stylers: [{ visibility: 'off' }],
                    },

                    //  {
                    //     featureType: "landscape.man_made", //地块， 包括门牌号
                    //     elementType: "geometry.stroke",
                    //     stylers: [ { visibility: 'off' },{color:'f1f1f1'}],
                    // },
                    //  {
                    //     featureType: "landscape.man_made", //地块， 包括门牌号
                    //     elementType: "geometry",
                    //     stylers: [ { visibility: 'on' },{color:'e3e3e3'}], //building 
                    // },
                    {
                        featureType: "administrative.land_parcel", //地块， 包括门牌号
                        elementType: "labels.text",
                        stylers: [{ visibility: 'off' }, { color: '757575' }],
                    },
                    {
                        featureType: "administrative.land_parcel", //地块， 包括门牌号
                        elementType: "geometry",
                        stylers: [{ visibility: 'on' }, { color: 'ffffff' }],
                    },




                    {
                        featureType: "poi",
                        elementType: "geometry",
                        stylers: [{ color: "#eeeeee" }],
                    },
                    {
                        featureType: "poi",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#757575" }],
                    },
                    {
                        featureType: "poi.park",
                        elementType: "geometry",
                        stylers: [{ color: "f1f1f1" }],
                    },
                    {
                        featureType: "poi.park",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#757575" }],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry",
                        stylers: [{ color: "#ffffff" }],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#ffffff" }],
                    },
                    {
                        featureType: "road.arterial",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#757575" }],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [{ color: "#f1f1f1" }],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#757575" }],
                    },
                    {
                        featureType: "road.local",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#757575" }, { visibility: 'off' }],
                    },
                    {
                        featureType: "transit.line",
                        elementType: "geometry",
                        stylers: [{ color: "#e5e5e5" }, { visibility: 'on' }],
                    },
                    {
                        featureType: "transit.station",
                        elementType: "geometry",
                        stylers: [{ color: "#eeeeee" }, { visibility: 'on' }],
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [{ color: "#e3e3e3" }],
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#757575" }],
                    },

                ],
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUI: true,
                gestureHandling: 'greedy',
                maxZoom: 25,


                URL: ''
                // direction options below





            },
            // DirectionsRequest: {
            //     origin: {},
            //     destination: {},
            //     travelMode: 'TRANSIT',




            // },


            keyword: '',
            searched: false,
            showSearch: false,
            categories: ['food', 'entertainment', 'healthy', 'fashion'],
            center: { "lat": -37.8099039, "lng": 144.9625258 },
            currentLocation: { "lat": -37.8099039, "lng": 144.9625258 },
            selectedMerchant: null,
            melbourne: { "lat": -37.8099039, "lng": 144.9625258 },
            isnavigate: true,
            showmap: false,
            showCouponCode: false




        }
    },
    mounted() {
        this.getAllDocs();
        this.$swal('活动暂未开始');
        if (this.$route.query.id) {

            this.getDoc(this.$route.query.id)
        }
        if (!this.$route.query.id) {
            this.getMyPosition();
        }
    },



    methods: {
        markerInfo(marker) {
            this.selectedMerchant = marker;
            this.center = this.selectedMerchant.position
            console.log('clicked')

        },
        getRandomBackgroundColor() {
            const fruitColors = ['#FFA07A', '#FF6347', '#FF7F50', '#FF8C00', '#FFA500', '#F08080', '#CD5C5C', '#DC143C', '#B22222', '#8B0000', '#DA70D6', '#FF69B4', '#FFC0CB', '#FF1493', '#C71585', '#DB7093', '#FFD700', '#F0E68C', '#FFFF00', '#ADFF2F'];
            let randomIndex = Math.floor(Math.random() * fruitColors.length);
            let color = fruitColors[randomIndex];
            return color;
        },
        async getAllDocs() {
            const querySnapshot = await getDocs(collection(this.$db, "shops"));
            var initDBList = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                var data = doc.data();
                data.id = doc.id
                initDBList.push(data);
                // console.log(this.currentLocation)
                this.markers = initDBList;
                console.log(this.markers)
            });

        },
        async getDoc(id) {
            const docRef = doc(this.$db, "merchants", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Document id:", docSnap.data());

                this.selectedMerchant = docSnap.data();
                this.selectedMerchant.id = docSnap.id;
                this.center = this.selectedMerchant.position
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        },
        getMyPosition() {
            var self = this;
            var forCenter = {};


            const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            };

            function success(pos) {

                var crds = pos.coords
                console.log(pos)
                forCenter = { "lat": crds.latitude, "lng": crds.longitude }
                self.center = forCenter;
                self.currentLocation = forCenter;


            }

            function error(err) {
                console.warn(`ERROR(${err.code}): ${err.message}`);
            }





            navigator.geolocation.getCurrentPosition(success, error, options);

        },






        cutover() {
            this.$router.push('/List')
        },
        floatbtn() {
            this.$router.push('/Ranking')
        },
        popup(id) {
            this.$router.push({
                path: '/Profile',
                query: {
                    id: id
                }
            })
        },
        navigation() {
            this.isnavigate = true;
            this.showmap = true;


        },


        withgooglemap() {
            this.URL = 'https://www.google.com/maps/search/?api=1&query=' + this.selectedMerchant.name + ',' + this.selectedMerchant.address + ',' + this.selectedMerchant.position.lat + ',' + this.selectedMerchant.position.lng

            window.open(this.URL, '_blank')
        },
        withapplemap() {

            window.open('https://www.google.com/maps/search/?api=1&query=' + this.selectedMerchant.name + ',' + this.selectedMerchant.address + ',' + this.selectedMerchant.position.lat + ',' + this.selectedMerchant.position.lng, "_blank")


        },
        closepopup() {
            this.selectedMerchant = null;
            this.isnavigate = false;
            this.showmap = false
        },
        cancel() {
            this.showmap = false
        },
        openCouponPopUp() {
            this.showCouponCode = true
        },
        closeCouponPopUp() {
            this.showCouponCode = false
        }





    },

    computed: {
        google: gmapApi,
        searchedList() {
            var searchList
            var self = this

            if (!this.keyword) {
                searchList = this.markers
            }
            if (this.keyword) {
                searchList = self.markers.filter(marker => marker.name.toString().toLowerCase().includes(self.keyword.toLowerCase()))


            }
            return searchList


        },


    },

    components: { CouponCode },
    watch: {
        keyword(newVal) {
            if (newVal != '') {
                this.showSearch = true;
                this.searched = false;
            }
            if (newVal == '') {
                this.showSearch = false;
                this.searched = false;
            }
        }
    }
}
</script>
<style scoped>
.search-bar {
    height: 48px;

    margin-top: 72px;
}

#input {
    position: relative;
}

#img {
    position: absolute;
    left: 0;
}

#store-number {
    position: relative;
}

/*.input-group-text {
    display: block !important;
    background-color:#FFEEEB;
    border-right: none;
}*/

.wrapper {
    position: fixed;
    z-index: 100;
    /*left: 30px;*/



    /*padding-right: 30px;*/
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;


}

.bottom {
    position: fixed;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;





}

.btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;


    border-radius: 40px;

}

#area {
    color: white;
    background-color: red;
}

#store-number {
    background-color: white;
    color: black;
}



.filter-tags {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    background-color: white;
    padding: 6px 12px;
    margin-right: 8px;
    border-radius: 22px;
}


#append {
    background-color: #FFEEEB;
}

.float {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 125px;
    right: 5px;
    background-color: #0C9;
    color: white;
    border-radius: 50px;
    text-align: center;
    /*    box-shadow: 2px 2px 3px white;*/
    z-index: 100;
}

.my-float {
    margin-top: 13px;
    margin-right: 2px;
    padding: 1px;
}

.no-line-change {
    white-space: nowrap;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tag {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding: 2px 10px;
    border-radius: 29px;
}

#weightedIndex {
    background-color: #FF2E00;
    color: #FFFFFF;
}

#discount {
    background-color: #FFEEEB;
    color: #FF2E00;
}

#catagory2 {
    background-color: #F2F2F2;
    color: #7E7E7E;
}

.description {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7E7E7E;
}

.navigation {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.storepopup {
    background-color: white;
    position: fixed;
    bottom: 47px;
    z-index: 100;
    width: 320px;
    padding: 16px;
    left: 50%;
    transform: translate(-50%);
    border-radius: 8px;






}

.map-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}


.store-name {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
}

.store-addr {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
}

.navigatemap {
    position: fixed;
    z-index: 120;
    bottom: 0px;
    background: white;
    width: 100vw;
    border-radius: 16px 16px 0px 0px;

    height: 150px;
}
</style>