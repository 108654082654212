<template>
  <div class="container h-100">
    <div class="row">
      <div class="col">
        <div class="Sort">
          Sort by
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="space1" style="height:1px;padding-right: 0px;margin-bottom: 16px;margin-top: 24px;background-color:grey;"> </div>
        <div class="row">
          <div class="col text-start">
            <div class="metric">
              <button @click='metric'>Metric 1</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default{
  data() {
      return {

      }
    },
    mounted() {

    },
    methods: {
      

    },
    components: {}
}

</script>
<style scoped>
</style>
