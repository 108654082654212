<template>
    <div>
        <div class="wrapper" style="padding:0px">
            <div v-if="this.isDisplayed" @click='closePopUp' class="blur " style="padding:0px;">
            </div>
            <div class="counponPopUp" style="height:auto;width: 100%;">
                <div class="content text-center" v-if="this.isDisplayed">
                    <div class="couponInput " style="padding:20px">
                        <div class="row">
                            <div class="col text-end " @click='closePopUp'>X</div>
                        </div>
                        <div class="validatePhone text-start " style="margin-top:10px;margin-left: 20px;">
                            <div class="form-group " :class="{ 'form-group--error bg-gradient-warning text-danger ': $v.phone.$error }">
                                <label class="form__label ">Phone</label>
                                <div class="row">
                                    <div class="col-auto " style="padding-right: 0px;">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">+61</span>
                                        </div>
                                    </div>
                                    <div class="col" style="padding-left: 0px;">
                                        <input v-model="phone" class="form__input" placeholder="PhoneNumber" />
                                    </div>
                                </div>
                                <div v-if="submitted">
                                    <div class="error  " v-if="!$v.phone.required">Field is required</div>
                                    <div class="error  " v-if="!$v.phone.minLength">Phone number must have at least {{$v.phone.$params.minLength.min}} letters.</div>
                                    <div class="error " v-if="!$v.phone.numeric">Please enter number.</div>
                                </div>
                            </div>
                        </div>
                        <div class="couponSubmit text-center" style="margin-top:30px;">
                            <button @click="submit" id="sign-in-button" class="submitButton text-center" type="submit">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, numeric } from 'vuelidate/lib/validators'
import { setDoc } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
import axios from 'axios';

export default {
    props: ['couponCode'],
    data() {
        return {
            phone: '',
            isDisplayed: true,
            submitStatus: null,
            submitted: false,
            verificationCode: null
        }
    },
    validations: {
        phone: {
            required,
            numeric
        }
    },
    methods: {
        setPhone(value) {
            this.phone = value
            this.$v.phone.$touch()
        },
        closePopUp() {
            this.isDisplayed = false
            this.$emit('closeCouponEvent')
        },
        async checkInlfuencerVerified() {
            const docRef = doc(this.$db, "Influencers", this.phone);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                // if influencer exists and verified
                if (docSnap.data().verified) {
                    alert("Your coupon code is 123456")
                } else {
                    this.sendCode()
                }
            } else {
                this.addInfluencer()
            }
        },
        submit() {
            console.log('submit!')
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                this.checkInlfuencerVerified()
                // this.addDoc();
            }
        },
        sendCode() {
            axios.get('http://api.smsbroadcast.com.au/api.php', {
                    params: {
                        username: 'jiaxinruterry@gmail.com',
                        password: 'Jxr19940815=',
                        from: 'Influradar',
                        to: this.phone,
                        message: 'Your verification code is:' + this.verificationCode + ' please send it to Influradar official Red account https://shorturl.at/yzBU9 to be verified'
                    }
                })
                .then(function(response) {
                    console.log(response)
                    if (response.status == 200) {
                        console.log('message sent')
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        async addInfluencer() {
            this.verificationCode = Math.floor(1000 + Math.random() * 9000);
            await setDoc(doc(this.$db, "influencers", this.phone), {
                verified: false,
                verificationCode: this.verificationCode
            });
            this.sendCode()

        },
    },
    components: {},
    mounted() {}

}
</script>
<style scoped>
.input-group-text {
    width: 50px;
    height: 30px;
    padding-right: 0px;
    border-right: none;
    border-radius: 5px 0px 0px 5px;
}

.form__input {
    width: 100%;
    height: 30px;
    border-left: none;
    padding: 6px 12px 6px 12px;
    border-radius: 0px 5px 5px 0px;
    border: 0.5px solid grey;
}

.blur {

    height: 100%;
    width: 100%;
    opacity: 0.6;
    background-color: #515151;
    position: fixed;

    z-index: 9998;

}

.content {

    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;

    justify-content: center;

}

.couponInput {
    width: 400px;
    height: auto;
    top: 50%;
    left: 50%;
    background-color: white;

    transform: translate(-50%);
    justify-content: center;
    position: fixed;
    border-radius: 20px;
}

.submitButton {
    border-radius: 10px;
    border: 1px solid grey;
    padding: 5px 10px 5px 10px;

}
</style>