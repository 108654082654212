<template>
    <div class="container detail-section h-100">
        <div class="row h-100" style="margin-top:16px;" v-for='(store,index) in stores' :key='index'>
            <div class="col-auto">
                <img src="/static/store-img.png">
            </div>
            <div class="col-auto mt-auto">
                <div class="row h-100 ">
                    <div class="col-12 text-start " style="padding-left:0px;">
                        <div class="store-name no-line-change" style="">{{store.name}}
                        </div>
                    </div>
                </div>
                <div class="row h-100 ">
                    <div class="col-12 mt-auto text-start" style="padding-left:0px;">
                        <div class="store-addr no-line-change">
                            {{store.address}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-auto" style="margin-top:14px">
                <div class="col-auto mt-auto" style="padding-right: 0px;">
                    <div class="tag mt-auto" id="times">
                        <div><span style="">x</span>1.3</div>
                    </div>
                </div>
                <div class="col-auto mt-auto" style="margin-left: 4px;padding-left: 0px;padding-right: 0px;">
                    <div class="tag mt-auto " id="discount">
                        <div>10% off</div>
                    </div>
                </div>
                <div class="col-auto mt-auto" style="margin-left: 4px;padding-left: 0px;padding-right: 0px;">
                    <div class="tag mt-auto" id="catagory2">
                        <div>Catagory</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-auto text-start">
                    <div class="description" id="title">
                        You should have :
                    </div>
                    <div class="row">
                        <div class="col-auto my-auto" style="padding-right: 0px;margin-top: 8px;">
                            <img src="/static/circle.png">
                        </div>
                        <div class="col-auto">
                            <div class="description" id="constraint 1">
                                At least 500 followers on Red
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto my-auto" style="padding-right: 0px;margin-top: 8px;">
                            <img src="/static/circle.png">
                        </div>
                        <div class="col-auto">
                            <div class="description" id="constraint 2">
                                More than 10 posts in last 30 days
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-auto text-start">
                    <div class="navigation"><span><img src="/static/car.png"></span> Navigate to store </div>
                </div>
            </div>
            <div class="space1" style="height:1px;padding-right: 0px;margin-top: 8px;margin-top: 24px;background-color:grey;"> </div>
        </div>
    </div>
</template>
<script type="text/javascript">
import jsonData from './demo.json'
export default {
    data() {

        return {
            stores: [],
        }
    },
    mounted() {
        this.stores = jsonData;
    }
}
</script>
<style scoped>
.no-line-change {
    white-space: nowrap;
    max-width: 240px;
    text-overflow: ellipsis;
    overflow-x: hidden;
}

.tag {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding: 2px 8px;
    border-radius: 33px;
}

#times {
    background-color: #FF2E00;
    color: #FFFFFF;
}

#discount {
    background-color: #FFEEEB;
    color: #FF2E00;
}

#catagory {
    background-color: #F2F2F2;
    color: #7E7E7E;
}

.description {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7E7E7E;
}

.navigation {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}
</style>