<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12 text-start" style="margin-top:10px">
                    <div class="row">
                        <div class="col-auto">
                            <div class="name">
                                <label>
                                    name
                                </label>
                                <div class="col"></div>
                                <input type="text" name="name" v-model='inputname' style="margin-right: 100px;">
                            </div>
                        </div>
                        <div class="col text-end" style="padding-top:20px">
                            <div class="transferData" @click='transferData'>
                                TransferData
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-start" style="margin-top:10px">
                    <div class="row">
                        <div class="position">
                            <div class="col-3">
                                <label>
                                    position
                                </label>
                            </div>
                            <div class="col"><input type="text" name="position" v-model='inputposition' placeholder="" style="margin-right:100px"></div>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-start" style="margin-top:10px">
                    <div class="row">
                        <div class="phone">
                            <div class="col-3">
                                <label>
                                    phone
                                </label>
                            </div>
                            <div class="col"> <input type="text" name="phone" v-model='inputphone'></div>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-start" style="margin-top:10px">
                    <div class="row">
                        <div class="address">
                            <div class="col-auto">
                                <label>
                                    address
                                </label>
                            </div>
                            <div class="col"> <input type="text" name="address" v-model='inputaddress'></div>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-start" style="margin-top:10px">
                    category
                    <select v-model='inputcategory' id="">
                        <label for="">
                            Category
                        </label>
                        <div class="col"></div>
                        <option value="null" disabled>select category</option>
                        <option value="food">food</option>
                        <option value="heath">heath</option>
                        <option value="fashion">fashion</option>
                        <option value="entertainment">entertainment</option>
                    </select>
                </div>
                <div class="col-12 text-start">
                    <div class="weightedIndex" style="margin-top:10px">
                        <div class="col"> <label>
                                weighted index
                            </label></div>
                        <div class="col"> <input type="number" name="weightedIndex" v-model='inputweightedIndex'></div>
                    </div>
                </div>
                <div class="col-12 text-start" style="margin-top:10px">
                    <div class="discount">
                        <div class="col"><label>
                                discount
                            </label></div>
                        <div class="col"><input type="number" name="discount" v-model='inputdiscount'></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="submit text-start" style="margin-top:10px"><button @click="addDoc">submit</button></div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center" style="margin-top:10px"><input type="text" name="ID" v-model='inputID' style="margin-right:20px"><button @click='getDoc'>getDoc</button></div>
            <div class="col-12 text-center" style="margin-top:10px"><button @click='getAllDocs'>getAll</button></div>
            <div class="col-12 text-center" style="margin-top:10px"><button @click='searchData'>searchData</button></div>
            <div class="row h-100" style="margin-top:10px">
            </div>
            <!--      <div class="dataTable" style="margin-top:50px">
                <table class="table">
                    <thead>
                        <th>name</th>
                        <th>phone</th>
                        <th>address</th>
                        <th>category</th>
                        <th>discount</th>
                        <th>position</th>
                        <th>weightedIndex</th>
                    </thead>
                    <tbody>
                        <tr v-for='(m,index) in merchantsDB' :key='index'>
                            <td>{{m.name}}</td>
                            <td>{{m.phone}}</td>
                            <td>{{m.address}}</td>
                            <td>{{m.category}}</td>
                            <td>{{m.discount}}</td>
                            <td>{{m.position}}</td>
                            <td>{{m.weightedIndex}}</td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
            <b-table hover :items="this.merchants"></b-table>
        </div>
    </div>
</template>
<script type="text/javascript">
import { collection, addDoc, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import testData from '@/components/testData.json'

export default {
    data() {
        return {
            inputname: '',
            inputposition: {},
            inputphone: '',
            inputaddress: '',
            inputweightedIndex: '',
            inputdiscount: '',
            inputcategory: '',
            inputID: '',

            merchants: [],
            merchantsDB: []

        }
    },
    mounted() {
        this.merchants = testData
        console.log(this.merchants)





    },
    methods: {
        async addDoc() {
            const docRef = await addDoc(collection(this.$db, "merchants"), {

                name: this.inputname,
                position: this.inputposition,
                phone: this.inputphone,
                address: this.inputaddress,
                category: this.inputcategory,
                weightedIndex: this.inputweightedIndex,
                discount: this.inputdiscount
            });
            console.log("Document written with ID: ", docRef.id);

        },
        async transferData() {
            
            for (let i = 0; i <= this.merchants.length - 1; i++) {

                


                await addDoc(collection(this.$db, "merchants"), {

                    name: this.merchants[i].name,
                    position:this.merchants[i].position,
                    phone: this.merchants[i].phone,
                    address: this.merchants[i].address,
                    category: this.merchants[i].category,
                    weightedIndex: 1,
                    discount: 0
                });
                console.log(this.merchants[i].id)


            }


        },
        async getDoc() {
            const docRef = doc(this.$db, "merchants", this.inputID);
            const docSnap = await getDoc(docRef);
            var searchIDList = [];


            if (docSnap.exists()) {
                console.log("Document id:", docSnap.data());
                searchIDList.push(docSnap.data());
                this.merchantsDB = searchIDList;
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        },
        async getAllDocs() {
            const querySnapshot = await getDocs(collection(this.$db, "merchants"));
            var initDBList = [];

            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                initDBList.push(doc.data());
                this.merchantsDB = initDBList;
            });

        },
        async searchData() {
            const q = query(collection(this.$db, "merchants"), where("weightedIndex", '<=', 0.5));


            const querySnapshot = await getDocs(q);
            var searchConditionList = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                searchConditionList.push(doc.data());
                this.merchantsDB = searchConditionList;
            });

        }

    },
    // computed: {
    //     getAllDocs() {
    //         const querySnapshot = await getDocs(collection(this.$db, "merchants"));
    //         var initDBList

    //         querySnapshot.forEach((doc) => {
    //             // doc.data() is never undefined for query doc snapshots
    //             console.log(doc.id, " => ", doc.data());
    //             initDBList.push(doc.data());
    //             return initDBList;
    //         });
    //     },
    //     searchData() {
    //         const q = query(collection(this.$db, "merchants"), where("weightedIndex", '<=', 0.5));


    //         const querySnapshot = await getDocs(q);
    //         var searchConditionList = [];
    //         querySnapshot.forEach((doc) => {
    //             // doc.data() is never undefined for query doc snapshots
    //             console.log(doc.id, " => ", doc.data());
    //             searchConditionList.push(doc.data());
    //             return searchConditionList;
    //         });
    //     },
    //     getDoc() {
    //         const docRef = doc(this.$db, "merchants", this.inputID);
    //         const docSnap = await getDoc(docRef);
    //         var searchIDList = [];

    //         if (docSnap.exists()) {
    //             console.log("Document id:", docSnap.data());
    //             searchIDList.push(docSnap.data());
    //             return searchIDList;
    //         } else {
    //             // doc.data() will be undefined in this case
    //             console.log("No such document!");
    //         }
    //     }
    // }



}
</script>
<style type="text/css">
</style>