<template>
  <div class="container h-100">
    <div class="row">
      <div class="col-auto text-start">
        <div class="catagory-title">
          Catagory
        </div>
      </div>
      <div class="col text-end">
        <div class="Update">
          Update
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="space1" style="height:1px;padding-right: 0px;margin-bottom: 16px;margin-top: 24px;background-color:grey;"> </div>
        <div class="row">
          <div class="col-auto text-start">
            <div>
              <b-form-checkbox id="checkbox-1" v-model="status" name="checkbox-1" value="accepted" unchecked-value="not_accepted">
                <div class="Catagory">
                  Catagory 1
                </div>
              </b-form-checkbox>
              <div>State: <strong>{{ status }}</strong></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="space1" style="height:1px;padding-right: 0px;margin-bottom: 16px;margin-top: 20px;background-color:grey;"> </div>
        <div class="row">
          <div class="col-auto text-start">
            <div>
              <b-form-checkbox id="checkbox-1" v-model="status" name="checkbox-1" value="accepted" unchecked-value="un_accepted">
                <div class="Catagory" style="margin-left:12px">
                  Catagory 2
                </div>
              </b-form-checkbox>
              <div>State: <strong>{{ status }}</strong></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  data() {
    return {
      status: "not_accepted"
    }
  },
  mounted() {

    },
    methods: {
      

    },
    components: {}
}

</script>
<style scoped>
.Update {
  color: red;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.catagory-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

</style>