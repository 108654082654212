<template>
    <div class="container statistics-section">
        <div class="row text-center">
            <div class="col-lg-3 col-12">
                <div class="statistics">
                    + 25<span>M</span>
                </div>
                <div class="type">
                    Views
                </div>
            </div>
            <div class="col-lg-3 col-12">
                <div class="statistics">
                    + 12<span>K</span>
                </div>
                <div class="type">
                    Influecers
                </div>
            </div>
            <div class="col-lg-3 col-12">
                <div class="statistics">
                    + 66.67<span>%</span>
                </div>
                <div class="type">
                    Effective exposure
                </div>
            </div>
            <div class="col-lg-3 col-12">
                <div class="statistics">
                    + 15.90<span>%</span>
                </div>
                <div class="type">
                    Conversion rate
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Statistics"
}
</script>
<style scoped>
.statistics {
    font-size: 50px;
    color: #1F2C39;
    font-weight: 800;
}

.statistics span {
    color: #FF0000;
}

.type {
    font-size: 23px;
    font-weight: 800;
}

.statistics-section {
    padding-top: 50px;
    padding-bottom: 50px;
}
</style>