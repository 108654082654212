<template>
  <div>
    <div class="wrapper">
      <div class="row ">
        <div class="col-12 text-start">
          <div class="search-bar">
            <b-input-group>
              <template #prepend>
                <b-input-group-text style="border-right: none;border-top-right-radius:0px;border-bottom-right-radius: 0px ;"><svg t="1666765693238" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2801" width="15" height="15">
                    <path d="M469.333333 768c-166.4 0-298.666667-132.266667-298.666666-298.666667s132.266667-298.666667 298.666666-298.666666 298.666667 132.266667 298.666667 298.666666-132.266667 298.666667-298.666667 298.666667z m0-85.333333c119.466667 0 213.333333-93.866667 213.333334-213.333334s-93.866667-213.333333-213.333334-213.333333-213.333333 93.866667-213.333333 213.333333 93.866667 213.333333 213.333333 213.333334z m251.733334 0l119.466666 119.466666-59.733333 59.733334-119.466667-119.466667 59.733334-59.733333z" fill="#444444" p-id="2802"></path>
                  </svg></b-input-group-text>
              </template>
              <b-form-input style="border-left:none ;border-top-right-radius: 8px;border-bottom-right-radius: 8px;" v-model="inputValue" placeholder="Enter your name"></b-form-input>
              <template #append>
                <b-input-group-text style="margin-left:8px;">
                  <svg t="1666835703088" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4548" width="15" height="15">
                    <path d="M110.025 252.249c0 13.741 5.456 27.374 14.899 37.112s22.663 15.364 35.987 15.364 26.544-5.626 35.987-15.364c9.441-9.738 14.899-23.371 14.899-37.112s-5.456-27.375-14.899-37.111c-9.442-9.738-22.663-15.364-35.987-15.364s-26.544 5.626-35.987 15.364c-9.338 9.736-14.899 23.37-14.899 37.111m0 0zM103.625 512.575c0 13.741 5.455 27.482 14.899 37.22 9.442 9.738 22.663 15.364 36.091 15.364 13.324 0 26.649-5.626 36.091-15.364s14.899-23.371 14.899-37.22c0-13.741-5.455-27.482-14.899-37.22-9.442-9.738-22.662-15.364-36.091-15.364-13.324 0-26.649 5.626-36.091 15.364-9.444 9.737-14.899 23.37-14.899 37.22m0 0zM103.625 774.089c0 13.741 5.455 27.482 14.899 37.22 9.442 9.738 22.663 15.364 36.091 15.364 13.324 0 26.649-5.626 36.091-15.364s14.899-23.37 14.899-37.22c0-13.741-5.455-27.482-14.899-37.22-9.442-9.737-22.662-15.364-36.091-15.364-13.324 0-26.649 5.627-36.091 15.364-9.444 9.847-14.899 23.479-14.899 37.22m0 0zM919.041 249.869c0 27.699-19.935 50.095-44.59 50.095H345.88c-24.655 0-44.59-22.397-44.59-50.095 0-27.699 19.935-50.095 44.59-50.095h528.571c24.656-0.001 44.59 22.396 44.59 50.095m0 0zM919.041 510.195c0 27.59-19.935 50.095-44.59 50.095H345.88c-24.655 0-44.59-22.398-44.59-50.096 0-27.699 19.935-50.096 44.59-50.096h528.571c24.656-0.109 44.59 22.397 44.59 50.097m0 0zM919.041 771.601c0 27.699-19.935 50.096-44.59 50.096H345.88c-24.655 0-44.59-22.397-44.59-50.096 0-27.591 19.935-49.988 44.59-49.988h528.571c24.656-0.108 44.59 22.397 44.59 49.988m0 0z" fill="#09B6BA" p-id="4549"></path>
                  </svg>
                </b-input-group-text>
              </template>
            </b-input-group>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top:8px">
        <div class="col-auto">
          <div class="filter-tags" id="food" style="margin-top:8px;padding-left: 12px;padding-right: 12px;padding-top: 6px;padding-bottom: 6px;border-radius: 22px;">
            Food
          </div>
        </div>
        <div class="col-auto">
          <div class="filter-tags" id="ent" style="margin-top:8px;padding-left: 12px;padding-right: 12px;padding-top: 6px;padding-bottom: 6px;border-radius: 22px;">
            Entertaiment
          </div>
        </div>
        <div class="col-auto">
          <div class="filter-tags" id="fashion" style="margin-top:8px;padding-left: 12px;padding-right: 12px;padding-top: 6px;padding-bottom: 6px;border-radius: 22px;">
            Fashion
          </div>
        </div>
        <div class="col-auto">
          <div class="filter-tags" id="health" style="margin-top:8px;padding-left: 12px;padding-right: 12px;padding-top: 6px;padding-bottom: 6px;border-radius: 22px;">
            Healthy
          </div>
        </div>
      </div>
    </div>
    <GmapMap :center="{lat:-37.8099039, lng:144.9625258}" :zoom="13" map-type-id="terrain" style="width: 100vw; height: 100vh" :options="mapOptions">
      <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="false" @click="center=m.position" />
    </GmapMap>
    <div class="bottom">
      <div class="row my-auto">
        <div class="col-auto text-center">
          <div class="btn" id="area">
            Search this area
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  data() {
    return {
      mapOptions: {
        styles: [{
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }],
          },
          {
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            elementType: "labels.text.fill",
            stylers: [{ color: "#616161" }],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [{ color: "#f5f5f5" }],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [{ color: "#bdbdbd" }],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{ color: "#eeeeee" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#757575" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#e5e5e5" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9e9e9e" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [{ color: "#757575" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#dadada" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#616161" }],
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9e9e9e" }],
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [{ color: "#e5e5e5" }],
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [{ color: "#eeeeee" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#c9c9c9" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9e9e9e" }],
          },
        ],
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: true,
      }

    }
  }
}

</script>
<style scoped>
.bottom {
position: fixed;
z-index: 100;
left: 50%;
transform: translateX(-50%);
bottom: 40px;
}
.btn{
font-weight: 500;
font-size: 16px;
line-height: 16px;
background-color: red;
color: white;
border-radius: 40px;
}

#input {
  position: relative;
}

#img {
  position: absolute;
  left: 0;
}

.wrapper {
  position: fixed;
  z-index: 100;
  left: 30px;
  top: 72px;
}

.input-group-text {
  display: block !important;
  background-color: white;
  border-left: none;
}

.filter-tags {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background-color: white;
}

</style>
