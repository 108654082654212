<template>
    <div>
        <Nav />
        <Hero />
        <Statistics />
        <About />
        <Services />
        <Influencers />
        <!-- <Posts /> -->
        <Footer />
    </div>
</template>
<script>
import Nav from "@/components/landing/Nav.vue"
import Hero from "@/components/landing/Hero.vue"
import Statistics from "@/components/landing/Statistics.vue"
import About from "@/components/landing/About.vue"
import Services from "@/components/landing/Services.vue"
import Influencers from "@/components/landing/Influencers.vue"
// import Posts from "@/components/landing/Posts.vue"
import Footer from "@/components/landing/Footer.vue"


export default {
    name: "Home",
    components: {
        Nav,
        Hero,
        Statistics,
        About,
        Services,
        Influencers,
        // Posts,
        Footer
    }
}
</script>
<style scoped>
/* hero section starts here */

@font-face {
    font-family: "Pacifico";
    src: url("/fonts/Pacifico-Regular.ttf") format("truetype");
}

</style>