<template>
    <div class="r-background" style="padding:30px">
        <div class="row">
            <div class="col-auto">
                <div class="ranking-back">
                    <button @click="gobackGmap" style="border:none;background:none"><img src="/static/ranking-back.png"></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-auto" style="margin-top:30px">
                <div class="Ranking">
                    Ranking
                </div>
            </div>
        </div>
        <div class="row mt-auto">
            <div class="col-auto text-start" style="margin-top: 4px;">
                <div class="update">Last update:5 mins ago</div>
            </div>
        </div>
        <div class="" style="padding:0px;">
            <div class="row" style="margin-top: 20px;" v-for="(influencer, index) in influencers.slice(0,3)" :key="index">
                <!-- remove padding right 0 px can be or add g-0 to row problem will be solved -->
                <div class="col-12" style="margin-bottom: 12px;">
                    <div class="first-three text-start shadow-sm " id="first" style="position:relative;">
                        <div class="ranks" style="padding-left:7px;padding-right:7px; position:absolute; top: 0px; left: 0px;">
                            <div>{{index+1}}<span v-if="index==0">st</span> <span v-if="index==1">nd</span> <span v-if="index==2">rd</span> </div>
                        </div>
                        <div class="row">
                            <div class="col-auto my-auto text-start">
                                <img src="/static/head-img1.png">
                            </div>
                            <div class="col my-auto text-start ">
                                <div class="name">
                                    {{influencer.name}}
                                </div>
                                <div class="likes-number"><span class="number">{{influencer.likes}} </span><img src="/static/likes.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top:30px;">
                <div class="col-12">
                    <div class="left shadow-sm" style="padding:24px">
                        <div class="row" v-for="(influencer, index) in influencers.slice(3,influencers.length)" :key="index" style="margin-top:12px;width: 100%; ">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-auto text-start">
                                        <img src="/static/head-img-left1.png">
                                    </div>
                                    <div class="col-auto text-start">
                                        <div class="left-name">{{influencer.name}}</div>
                                    </div>
                                    <div class="col text-end">
                                        <div class="left-likes"><span class="number">{{influencer.likes}} </span><img src="/static/likes.png"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="space2" style="height:1px;padding-right: 0px;margin-top: 15px; margin-bottom: 15px;background-color: #F2F2F2;"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
import Vue from 'vue';
import Mint from 'mint-ui';
Vue.use(Mint);
export default {
    data() {
        return {
            influencers: [{

                    "name": "isaac Feng",

                    "likes": "3217"
                },
                {
                    "name": "Jenniffer Smith",
                    "likes": "3012"
                },
                {
                    "name": "John Wong",
                    "likes": "2999"
                },
                {
                    "name": "Nina",
                    "likes": "2100"
                },
                {
                    "name": "Sally",
                    "likes": "1937"
                },
                {
                    "name": "Jojo Chan",
                    "likes": "1918"
                },
                {
                    "name": "Jojo Chan",
                    "likes": "1918"
                },

            ],

        }
    },
    methods: {
        gobackGmap() {
            this.$router.go(-1)
        }
    }
}
</script>
<style scoped>
body {
    max-width: 100%;
    overflow-x: hidden;
}

.Ranking {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
}

.update {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
}

.name {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

}

.likes {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FF6565;

}

.first-three {
    /*width: 315px;*/
    width: 100%;
    background-color: white;
    padding: 24.5px;
    border-radius:8px ;
   
    
    
    max-width: 100%;
}

.ranks {
    position: absolute;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: white;
    background-color: red;
    border-radius: 8px 0px;
}

.number {
    color: red;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

.r-background {
    background-color: red;
    background: url("/static/r-background.png");
    background-size: cover;
}

.left {
    background-color: white;
    width: 100%;
    border-radius:8px ;
   
    
   
    
}
</style>