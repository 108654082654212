<template>
    <div>
        <div>
            <mt-swipe v-if="chooseMerchant.hasOwnProperty('images')" style="height: 240px;" :auto="4000">
                <mt-swipe-item v-for="(image,index) in chooseMerchant.images" :key="index">
                    <img class="shop-image" :src="image">
                </mt-swipe-item>
            </mt-swipe>
            <mt-swipe v-if="!chooseMerchant.hasOwnProperty('images')" style="height: 240px;" :auto="4000">
                <mt-swipe-item v-for="(image,index) in [1,2,3,4,5]" :key="index">
                    <img class="shop-image" src="https://placehold.co/600x400">
                </mt-swipe-item>
            </mt-swipe>
            <div class="row">
                <div class="col-auto">
                    <div class="back">
                        <button @click="gobackList" style="border:none;background:none;"><img src="/static/Back1.png"></button>
                    </div>
                </div>
                <div class="col-auto ">
                    <div class="sharebtn" @click='sharelink'>
                        <img src="/static/share.png">
                    </div>
                </div>
            </div>
        </div>
        <div class="container detail-section" v-if="chooseMerchant">
            <div class="row">
                <div class="col-auto">
                    <img src="/static/st_logo.svg">
                </div>
                <div class="col">
                    <div class="row h-100 ">
                        <div class="col-12 text-start " style="padding-left:0px">
                            <div class="store-name" style="">{{chooseMerchant.name}}
                            </div>
                        </div>
                        <div class="col-auto mt-auto" style="margin-left: 0px;padding-left: 0px;padding-right: 0px;">
                            <div class="tag mt-auto" id="times">
                                <div><span style="">x</span>{{chooseMerchant.weightedIndex}}</div>
                            </div>
                        </div>
                        <div class="col-auto mt-auto" style="margin-left: 4px;padding-left: 0px;padding-right: 0px;">
                            <div class="tag " id="discount">
                                <div>{{chooseMerchant.influencerDiscount}}% off</div>
                            </div>
                        </div>
                        <div class="col-auto mt-auto" style="margin-left: 4px;padding-left: 0px;padding-right: 0px;">
                            <div class="tag " id="catagory">
                                <div>{{chooseMerchant.category}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row h-100">
                <div class="row" style="padding-bottom: 0px;margin-top: 24px;">
                    <div class="col-auto" style="padding-right:0px">
                        <img src="/static/addr.png">
                    </div>
                    <div class="col text-start mt-auto " style="padding-left:0px;margin-left:8px;">
                        <div class="address mt-auto h-100" id="address">{{chooseMerchant.address}}</div>
                    </div>
                </div>
                <div class="row" style="padding-bottom:0px;margin-top:8px ;">
                    <div class="col-auto" style="padding-right:0px">
                        <img src="/static/pho.png">
                    </div>
                    <div class="col-auto text-start mt-auto" style="padding-left:0px;margin-left:8px;">
                        <div class="phone mt-auto" id="phone" style="padding-bottom: 0px;">
                            {{ chooseMerchant.phone }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row h-100">
                <div class="col-auto text-start" style="padding-right: 0px;margin-top: 8px;">
                    <div class="description mt-auto" id="des">
                        {{ chooseMerchant.desc }}
                    </div>
                </div>
            </div>
            <div class="row h-100">
                <div class="col-12 text-start" style="padding-right: 0px;margin-top: 24px;">
                    <div class="sub-title1" id="sub1">
                        Special Offer
                    </div>
                    <div class="space1" style="height:1px;padding-right: 0px;margin-top: 8px;background-color: #E7E7E7;"> </div>
                </div>
                <div class="row" style="margin-top: 12px;">
                    <div class="col-auto text-start" style="padding-right: 0px;margin-top: 8px;">
                        <div class="Special-list" v-html="chooseMerchant.offers" style="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row h-100">
                <div class="col- text-start" style="padding-right: 0px;margin-top: 24px;">
                    <div class="sub-title2" id="sub2">
                        Requirements
                    </div>
                    <div class="space2" style="height: 1px ;padding-right: 0px;margin-top: 8px;background-color: #E7E7E7;"> </div>
                </div>
                <div class="row" style="margin-top: 12px;">
                    <div class="col-auto text-start" style="padding-right: 0px;margin-top: 8px;">
                        <div class="Requirements-list" id="followers" v-html="chooseMerchant.requirements">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row h-100">
                <div class="mx-auto " style="margin-top:30px;">
                    <div class="col-auto text-center" @click='navigate'>
                        <div class="navigation" style="padding-bottom: 10px;padding-top: 10px;">
                            Navigate
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--  <div class="linkpopup" v-if='this.isshare'>
            <div class="msg" style="height:100%;width:100%">
                {{currenturl}}
                
                
                <button @click='copylink' style="r-background:grey;border:none;border-radius:10px">Copy</button>
                <button @click='closepopup' style="r-background:grey;border:none;border-radius:10px;margin-top:15px">
                    cancel
                </button>
            </div>
            
        </div> -->
    </div>
</template>
<script>
import { Toast } from 'mint-ui';
import { doc, getDoc } from "firebase/firestore";



export default {

    name: "Profile",
    components: {},
    data() {
        return {
            isshare: false,
            currenturl: '',
            URL: '',
            merchant: [],
            prevRoute: null,
            chooseMerchant: null,

            markers: [

            ]





        }
    },
    beforeRouteEnter(to, from, next) {
        console.log('from here:', from);
        next(vm => vm.prevRoute = from);
        console.log('to:', to)
    },
    computed: {
        // chooseMerchant() {
        //     let self = this


        //     var chosenMerchant = this.markers.find(marker => marker.id.toString().includes(self.$route.query.id))
        //     console.log(chosenMerchant)
        //     self.merchant = chosenMerchant
        //     return chosenMerchant



        // },

    },
    methods: {

        navigate() {
            // this.URL = 'https://www.google.com/maps/search/?api=1&query=' + this.chooseMerchant.name + ',' + this.chooseMerchant.address + ',' + this.chooseMerchant.position.lat + ',' + this.chooseMerchant.position.lng
            this.URL = 'https://www.google.com/maps/search/?api=1&query=' + this.chooseMerchant.name + ',' + this.chooseMerchant.address + ',' + this.chooseMerchant.position


            window.open(this.URL, '_blank')


        },
        async getShopById() {
            const docRef = doc(this.$db, "shops", this.$route.query.id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                var data = docSnap.data()
                data.id = docSnap.id;
                this.chooseMerchant = data
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }

        },



        gobackList() {

            // if come from map
            if (this.prevRoute.name == 'Gmap') {
                this.$router.push({
                    path: '/Gmap',
                    query: {
                        id: this.merchant.id
                    }
                })
            }
            if (this.prevRoute.name == 'List') {
                this.$router.push('/List')
            }


        },
        sharelink() {
            this.isshare = true
            this.currenturl = window.location.href
            navigator.clipboard.writeText(this.currenturl).then(function() {
                Toast({
                    message: 'Invitation Copied Successfully',
                    position: 'center',
                    duration: 1000
                });
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });

        }






    },
    watch: {

    },
    mounted() {
        this.getShopById()
        console.log(this.chooseMerchant)
    }


}
</script>
<style scoped>
.shop-image {
    width: 100%;
    height: 240px;
    /*left: 5px;*/
    /*top: 15px;*/
    object-fit: cover;
}

.back {
    position: absolute;
    height: 100%;
    width: 15.99px;
    left: 38px;
    top: 64px;
    z-index: 15;



}

.linkpopup {

    width: 300px;
    height: 200px;
    position: fixed;


    top: 50%;


    left: 50%;
    transform: translateX(-50%) translateY(-50%);



    background: white;
    z-index: 100;
    border-radius: 50px;

}

.msg {
    top: 50%;


    position: relative;
    z-index: 120;

}


.sharebtn {
    position: absolute;
    height: 24px;
    width: 24px;
    right: 30px;
    top: 60px;

}

.detail-section {
    padding-top: 24px;
    padding-bottom: 36px;
    padding-left: 30px;
    padding-right: 30px;
}

.store-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
}

.tag {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding: 2px 8px;
    border-radius: 33px;
}

.Special-list {
    font-family: 'Human Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.Requirements-list {
    font-family: 'Human Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.navigation {
    background-color: #FF0000;
    border-radius: 43px;
    color: white;
}

#times {
    background-color: #FF2E00;
    color: #FFFFFF;
}

#discount {
    background-color: #FFEEEB;
    color: #FF2E00;
}

#catagory {
    background-color: #F2F2F2;
    color: #7E7E7E;
}

#address {
    font-family: 'Human Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2E2E2E;

}

#phone {
    font-family: 'Human Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2E2E2E;
}

#des {
    font-family: 'Human Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

#sub1 {
    font-family: 'Human Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

#sub2 {
    font-family: 'Human Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}
</style>