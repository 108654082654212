import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Mint from 'mint-ui';
import 'mint-ui/lib/style.css'
Vue.use(Mint);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueClipboard from 'vue-clipboard2'
import VModal from 'vue-js-modal'

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

Vue.use(VModal)
Vue.use(BootstrapVue)

Vue.use(IconsPlugin)
Vue.use(VueClipboard)

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
// test mode
// const firebaseConfig = {
//     apiKey: "AIzaSyCfvBhEmmdLbzo9SrAXYIoC3-lm4woKCHA",
//     authDomain: "qrpay-tech-test.firebaseapp.com",
//     projectId: "qrpay-tech-test",
//     storageBucket: "qrpay-tech-test.appspot.com",
//     messagingSenderId: "922853246108",
//     appId: "1:922853246108:web:b2e9f0de85947302e29959",
//     measurementId: "G-VESJFW2X5L"
// };

// production mode
const firebaseConfig = {
    apiKey: 'AIzaSyClZ4fp-i7s6W5wLiaVNHNwQSJ8ZPFaru4',
    authDomain: 'qrpay-tech.firebaseapp.com',
    projectId: 'qrpay-tech',
    storageBucket: 'qrpay-tech.appspot.com',
    messagingSenderId: '849378211828',
    appId: '1:849378211828:web:729989914afc00cb83aba1',
    measurementId: 'G-G8X31W0XEF'
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

Vue.prototype.$db = db;



Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyClZ4fp-i7s6W5wLiaVNHNwQSJ8ZPFaru4',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then set installComponents to 'false'.
    //// If you want to automatically install all the components this property must be set to 'true':
    installComponents: true
})
/* eslint-disable no-new */
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')