<template>
    <div id="about" class="container about-us-section">
        <div class="row">
            <div class="col-lg-6 col-12 text-start">
                <div>
                    <img id="about-us-img" src="https://freevetis.com/epickify/wp-content/uploads/sites/30/2022/04/cool-young-black-woman-afro-curly-hairstyle-posing-2022-04-12-22-58-43-utc-1365x2048.jpg" alt="">
                    <img id="about-us-img-statistic" class="" src="../../assets/statistics.png" alt="">
                </div>
            </div>
            <div class="col-lg-6 col-12 text-justify my-auto">
                <div>
                    <div class="tag mb-4 ml-aut mr-auto">
                        About
                    </div>
                    <div>
                        <h2 class="about-heading mb-4">
                            Get To Know Us.
                        </h2>
                    </div>
                    <div>
                        <h3 class="about-sub-heading mb-2">
                            Communications
                        </h3>
                        <div class="mb-4">Influradar helps influencers and merchants quickly and easily connect, saving time in the communication process.</div>
                    </div>
                    <div>
                        <h3 class="about-sub-heading mb-2">
                            Verifications
                        </h3>
                        <div class="mb-4">Influradar helps merchants to verify the identity of an influencer and generate dynamic deals for them.</div>
                    </div>
                    <div>
                        <h3 class="about-sub-heading mb-2">
                            Data Tracking
                        </h3>
                        <div class="mb-4">Influradar helps merchants to monitor performance of influencer campaign.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "About"
}
</script>
<style scoped>
.about-us-section {
    padding-top: 50px;
    padding-bottom: 50px;
}

.tag:before {
    background-image: url('/hash.png');
    background-size: 15px 15px;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: -10%;
/*    vertical-align: middle;*/
    width: 15px; 
    height: 15px;
    content:"";
}

.tag {
    background-color: #FFF3F3;
    color: #DF0000;
    width: fit-content;
    padding: 6px 12px;
    border-radius: 56px;
}

#about-us-img {
    max-width: 100%;
    height: 650px;
    width: auto;
    border-radius: 25px;
}

#about-us-img-statistic {
    border-radius: 25px;
    position: absolute;
    right: 10%;
    bottom: 5%;
    width: 250px;
    height: auto;
}

.about-heading {}
</style>